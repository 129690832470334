import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
export default {
  name: "parkingIotInfo",
  mixins: [mixins],
  data() {
    return {
      detailDrawer: false,
      currentId: null,
      columns: [{
        label: '支付方式',
        prop: 'payWay',
        width: '100px'
      }, {
        label: '支付模式',
        prop: 'payModel'
      }, {
        label: '商户号/应用id',
        prop: 'appId',
        customRender: props => {
          return props.row.payWay === '支付宝' ? props.row.appId : props.row.mchId;
        }
      }, {
        label: '子商户号/授权码',
        prop: 'appId',
        customRender: props => {
          return props.row.payWay === '支付宝' ? props.row.subAppId : props.row.subMchId;
        }
      }, {
        label: '公钥',
        prop: 'publicKey',
        showOverflowTooltip: true
      }, {
        label: '私钥',
        prop: 'privateKey',
        showOverflowTooltip: true
      }, {
        label: '微信P12证书',
        prop: 'p12',
        showOverflowTooltip: true
      }, {
        label: '微信商户密钥',
        prop: 'mchKey'
      }],
      topButtons: [{
        name: '新增',
        code: 'feePayParameter_add',
        type: 'primary',
        click: this.Add,
        permissions: ['feePayParameter_add']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'feePayParameter_edit',
        click: this.Edit,
        permissions: ['feePayParameter_edit']
      }],
      // 搜索字段
      searchItems: [{
        prop: 'payWay',
        label: '支付方式',
        type: 'select',
        items: [{
          name: '支付宝'
        }, {
          name: '微信'
        }],
        itemProp: {
          label: 'name',
          value: 'name'
        }
      }]
    };
  },
  methods: {
    Add() {
      this.$router.push('/feePayParameterAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/feePayParameterAdd',
        query: {
          id: row.id
        }
      });
    }
  },
  mounted() {}
};